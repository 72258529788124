import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEserKayitKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEserKayitKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  EserKayitlariSave: number;
  EserKayitlariSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  EserKayitlariKartById: any[];
  EserKayitlariKartById_dummy: any[];
}

export class EserKayitKart_ScreenBase extends React.PureComponent<IEserKayitKart_ScreenProps, any> {
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "1efe1ac6-2422-4d67-a481-50f0121b5704",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 604367, PropertyName: "label", Value: "Kaydet" },
        { Id: 993411, PropertyName: "value", Value: "Seslendiren" },
        { Id: 980508, PropertyName: "placeholder", Value: "..." },
        { Id: 11299, PropertyName: "value", Value: "Yapım Şirketi" },
        { Id: 329046, PropertyName: "placeholder", Value: "..." },
        { Id: 807853, PropertyName: "value", Value: "Kayıt Yılı" },
        { Id: 344736, PropertyName: "placeholder", Value: "..." },
        { Id: 91291, PropertyName: "value", Value: "ISRC" },
        { Id: 841928, PropertyName: "placeholder", Value: "..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.eserkayitkart_980508_value_kuikaTextInputRef = React.createRef();
    this.eserkayitkart_329046_value_kuikaTextInputRef = React.createRef();
    this.eserkayitkart_344736_value_kuikaTextInputRef = React.createRef();
    this.eserkayitkart_841928_value_kuikaTextInputRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      EserKayitlariSave: "",
      GoBack: "",
      EserKayitlariKartById: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("eserkayitkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EserKayitKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("eserkayitkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("eserkayitkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EserKayitKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      eserkayitkart_980508_value: this.state.EserKayitlariKartById?.at?.(0)?.seslendiren ?? undefined,
      eserkayitkart_329046_value: this.state.EserKayitlariKartById?.at?.(0)?.yapimSirketi ?? undefined,
      eserkayitkart_344736_value: this.state.EserKayitlariKartById?.at?.(0)?.kayitYili ?? undefined,
      eserkayitkart_841928_value: this.state.EserKayitlariKartById?.at?.(0)?.isrc ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EserKayitKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EserKayitKart/EserKayitKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserKayitlariKartById = result?.data.eserKayitlariKartById;
    formVars.eserkayitkart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].seslendiren
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].seslendiren
        : null
    );
    formVars.eserkayitkart_329046_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].yapimSirketi
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].yapimSirketi
        : null
    );
    formVars.eserkayitkart_344736_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].kayitYili
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].kayitYili
        : null
    );
    formVars.eserkayitkart_841928_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].isrc
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].isrc
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserKayitKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserKayitKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.eserkayitkart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].seslendiren
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].seslendiren
        : null
    );

    formVars.eserkayitkart_329046_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].yapimSirketi
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].yapimSirketi
        : null
    );

    formVars.eserkayitkart_344736_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].kayitYili
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].kayitYili
        : null
    );

    formVars.eserkayitkart_841928_value = ReactSystemFunctions.toString(
      this,
      stateVars.EserKayitlariKartById?.length > 0
        ? stateVars.EserKayitlariKartById[0].isrc
        : this.state.EserKayitlariKartById?.length > 0
        ? this.state.EserKayitlariKartById[0].isrc
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EserKayitKartComponent_604367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ISRC_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserkayitkart_841928_value", "value", "", "", "")
        ),
        "string"
      ),
      KayitYili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserkayitkart_344736_value", "value", "", "", "")
        ),
        "string"
      ),
      Seslendiren_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserkayitkart_980508_value", "value", "", "", "")
        ),
        "string"
      ),
      StokID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmStokID ?? this.props.screenInputs.prmstokid,
        "Guid"
      ),
      YapimSirketi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "eserkayitkart_329046_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EserKayitKart/EserKayitKartComponent_604367_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EserKayitlariSave = result?.data.eserKayitlariSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EserKayitKartComponent_604367_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EserKayitKartComponent_604367_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EserKayitKartComponent_896409_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
